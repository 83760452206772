/* Base styles to prevent white flash */
html, body, #root {
  margin: 0;
  padding: 0;
  background-color: #1a1a1a;
  min-height: 100vh;
  position: relative;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Animated backdrop */
.animated-backdrop {
  position: fixed;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
    rgba(255, 255, 255, 0.03) 10px,
    rgba(255, 255, 255, 0.03) 12px
  );
  animation: moveStripes 30s linear infinite;
  pointer-events: none;
  z-index: 0;
  width: 300%;
  height: 300%;
}

@keyframes moveStripes {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(33.33%, 33.33%);
  }
}

/* Ensure root elements stack properly */
#root {
  position: relative;
  z-index: 1;
  min-height: 100vh;
}

/* Update existing styles */
.App, .page {
  position: relative;
  z-index: 2;
}
