.App {
  background: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  position: relative;
  z-index: 2;
}

.App.transitioning {
  opacity: 0;
  transform: translateY(-50px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.intro {
  color: #ffffff;
  font-size: 2.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  letter-spacing: -0.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  padding: 0 2rem;
  max-width: 900px;
}

.typewriter {
  padding-right: 0.15em;
  white-space: nowrap;
  opacity: 0.9;
  position: relative;
}

.typewriter:not(.typing-complete)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0.15em;
  background-color: #ffffff;
  animation: blink-caret 0.75s step-end infinite;
}

.typewriter:nth-child(2) {
  font-size: 1.2rem;
  opacity: 0.8;
  font-weight: 300;
  white-space: normal;
  line-height: 1.5;
}

@keyframes blink-caret {
  from, to { opacity: 0 }
  50% { opacity: 1 }
}

.button-container {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
  justify-content: center;
}

.animated-button {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 0.8rem 1.5rem;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  border-radius: 4px;
  width: 120px;
}

.animated-button:nth-child(1) {
  animation: slideUp 0.6s ease-out 0.2s forwards;
}

.animated-button:nth-child(2) {
  animation: slideUp 0.6s ease-out 0.4s forwards;
}

.animated-button:nth-child(3) {
  animation: slideUp 0.6s ease-out 0.6s forwards;
}

.animated-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(-100%) skewX(-15deg);
  transition: transform 0.4s ease;
}

.animated-button:hover {
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

.animated-button:hover::before {
  transform: translateX(100%) skewX(-15deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.page-enter {
  opacity: 0;
  transform: translateY(50px);
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.page {
  background: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.page-content {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 300;
  letter-spacing: -0.5px;
  margin: 0;
  opacity: 0;
  animation: slideUp 0.6s ease-out 0.2s forwards;
}

.content-section {
  width: 100%;
  opacity: 0;
  animation: slideUp 0.6s ease-out 0.4s forwards;
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.back-button {
  position: fixed;
  top: 2rem;
  left: 2rem;
  opacity: 0;
  animation: fadeIn 0.6s ease-out 0.6s forwards;
}

.page-enter {
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

.page-enter-active {
  opacity: 1;
}

.social-icons {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  opacity: 0;
  animation: fadeIn 0.8s ease-out 0.8s forwards;
}

.social-icon {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.8rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.social-icon:hover {
  color: #ffffff;
  transform: translateY(-2px);
}

/* Keep the dark background only on the root level */
html, body, #root {
  margin: 0;
  padding: 0;
  background-color: #1a1a1a;
  min-height: 100vh;
  position: relative;
}
